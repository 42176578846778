/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~@nebular/theme/styles/theming';
@import '~@nebular/theme/styles/themes/default';
@import './theme/themes.scss';
/* framework component styles */

@import '~@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import './mixims/spacing.scss';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';

@import './mixims/dracula.scss';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* install the framework styles */
@include nb-install() {
  @include nb-theme-global();
  @include nb-auth-global();
};


@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('/../../assets/fonts/Poppins/Poppins-Thin.ttf') format("truetype");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('assets/fonts/Poppins/Poppins-Light.ttf') format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('assets/fonts/Poppins/Poppins-Regular.ttf') format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('assets/fonts/Poppins/Poppins-SemiBold.ttf') format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('assets/fonts/Poppins/Poppins-Bold.ttf') format("truetype");
  font-style: normal;
  font-weight: 700;
}

.d-flex {
  display: flex !important;
}

body, h1, h2, h3, h4, h5, h6, p {
 font-family: 'Poppins', sans-serif !important;
}

.nb-theme-default, nb-card, [nbInput] {
  font-family: 'Poppins', sans-serif !important;
}

.font-weight-thin {
  font-weight: 100;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-regular {
  font-weight: 400;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

.card{
  border: none !important;
}

.card-shadow {
  box-shadow: 0px 15px 15px rgba(50, 50, 71, 0.1), 0px 15px 15px rgba(50, 50, 71, 0.1);
}

.section-header {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 1.2rem 1rem;

  .section-alerts {
    font-weight: 300;
    font-size: 0.8rem;;
  }

  .section-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 2rem;
    /* identical to box height, or 200% */

    letter-spacing: 2px;
    text-transform: uppercase;

    /* Grayscale / Label */

    color: #6E7191;
    margin: 0;
  }

  .section-button {
    font-family: 'Poppins', sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    padding: 0.5rem 1rem !important;
    height: fit-content;
    color: #A0A3BD !important;
    background: transparent !important;
    border: transparent !important;
    text-transform: capitalize !important;
    letter-spacing: 0.75px;
  }
}

.list-item {
  .title {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.7rem;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #6E7191;
    padding: 0;
    margin: 0;
  }

  .detail {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.75px;
    color: #6E7191;
    margin: 0;
  }
}

.more-pages-modal {
	--background: 0;
}

.center-modal {
  height: 25rem !important;
  top: calc(50% - 12.5rem);
  border-radius: 30px;
}

// @media only screen and (min-width: 768px) and (min-height: 600px) {
//   .center-modal {
//     height: 500px !important;
//   }
// }

.full-button {
  position: relative;
  width: 48%;
  border-radius: 1.75rem !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  letter-spacing: 0.75px !important;
  text-transform: capitalize !important;
  padding: 0.9375rem 0.775rem !important;
}


// CARD COLORS

nb-tag {
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
  // color: #4E4B66;
  border: none !important;
}

.red-orange-card {
  background: linear-gradient(114.44deg, #eb0055 0%, #fffa80 100%);
  box-shadow: 0px 5px 10px 0px rgb(235 0 85 / 20%), 0px 5px 15px 2px rgb(255 251 128 / 20%), 0px 15px 15px rgb(50 50 71 / 10%) !important;
}

.blue-green-card {
  background: linear-gradient(114.44deg, #624AF2 0%, #50DDC3 100%);
  box-shadow: 0px 5px 10px 0px rgb(97 86 239 / 20%), 0px 5px 15px 2px rgb(81 209 198 / 20%), 0px 15px 15px rgb(50 50 71 / 10%) !important;
}

.purple-pink-card {
  background: linear-gradient(114.44deg, #7433FF 0%, #FFA3FD 100%);
  box-shadow: 0px 5px 10px 0px rgb(126 59 255 / 20%), 0px 5px 15px 2px rgb(243 154 253 / 20%), 0px 15px 15px rgb(50 50 71 / 10%) !important;
}

.light-card {
  background: #ffffff;
  color: black;
  box-shadow: 0px 5px 10px 0px rgb(181 181 181 / 20%), 0px 5px 15px 2px rgb(241 241 241 / 20%), 0px 15px 15px rgb(99 99 102 / 10%) !important;
}


/* MODAL GLOBAL */

.modal-default {

  --min-width: 100%;
  --min-height: 100%;
  
  ion-toolbar {

    --opacity: 0.3;
    border-bottom: rgba(143, 155, 179, 0.1882352941) 0.5px solid;
    margin-bottom: 8px;

    ion-title {
      font-size: 1.2em;
      font-weight: 500;
      line-height: 1.3em;
      letter-spacing: 0.0125em;
      text-align: start;
      // padding: 0 0 0 2.6em;
      padding: 0 0 0 0em;

    }
  }

  ion-content {
    --padding-end: 1.3rem;
    --padding-start: 1.3rem;
    --padding-top: 0.6rem;
  }
}

::ng-deep .inner-scroll {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.cdk-overlay-connected-position-bounding-box,
.cdk-global-overlay-wrapper,
.cdk-overlay-container{
z-index: 99999 !important;
}

.reorder-selected {
  box-shadow: 0px 16px 30px rgb(17 17 17 / 43%);
  border-radius: 32px;
}

.divider {
  border-bottom: #8f9bb330 0.5px solid;
}

ion-fab {
  nb-chat-form {
    width: 100%;
    position: relative;
    left: -44%;
    width: 92vw;
  }

  .page-button {
    position: relative;
    // left: -43%;
    width: 84vw;
    margin-bottom: env(safe-area-inset-top);

  }

}


.default-modal {
  margin-top: constant(safe-area-inset-top) !important;
  margin-top: env(safe-area-inset-top) !important;
}

@media (min-width: 650px) {
  html {
    font-size: 24px;
  }
}

@media (min-width: 800px) {
  html {
    font-size: 28px;
  }
}

@media (min-width: 950px) {
  html {
    font-size: 32px;
  }
}

ion-toast.default-toast::part(message) {
  font-size: 1rem !important;
}

ion-toast.default-toast::part(button) {
  font-size: 1rem !important;
  height: 2.75rem !important;
}

ion-popover {
  --width: 12.5rem !important;
}

.alert-message {
  font-size: 0.9rem !important;
}

.alert-button {
  font-size: 1rem !important;
  height: 2.75rem;
}

.alert-title {
  font-size: 1rem !important;
}

.alert-wrapper {
  min-width: 16.9rem !important;
}

.action-sheet-wrapper {
  width: 100% !important;
  max-width: 100% !important;
}

.action-sheet-button {
  height: 3.5rem !important;
  font-size: 1.25rem !important; 
}

.action-sheet-title {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  font-size: 0.9rem !important;
}