@forward '@nebular/theme/styles/theming';
@use '@nebular/theme/styles/theming' as *;
@use '@nebular/theme/styles/themes/default';

// $nb-enable-css-custom-properties: true; // <-- enable css custom properties

// $nb-themes: nb-register-theme((
//   // add your variables here like:
//   font-family-primary: 'Poppins' sans-serif,

//   font-family-secondary:'Poppins' sans-serif,

// ), cosmic, cosmic);

$font: 'Poppins', sans-serif;

$nb-themes: nb-register-theme((
  // add your variables here like:

  font-family-primary: $font,

  font-family-secondary: font-family-primary,

  color-primary-100: #E4D5FD,
  color-primary-200: #C7ABFC,
  color-primary-300: #A680F8,
  color-primary-400: #8A60F2,
  color-primary-500: #5F2EEA,
  color-primary-600: #4821C9,
  color-primary-700: #3517A8,
  color-primary-800: #240E87,
  color-primary-900: #180870,
  button-filled-basic-background-color: #f7f7fc,
  button-filled-basic-border-color: #f7f7fc,
  button-outline-basic-text-color: #222b45,
  // button-outline-basic-border-color: #2e3a59

  input-giant-max-width: 100vw,
  input-giant-padding: 1rem,
  button-group-filled-button-primary-text-color: #ffffff,
  card-scrollbar-background-color: transparent,
  card-scrollbar-color: transparent,
  layout-background-color: #ffffff,

), newdefault , default);

// $nb-themes: nb-register-theme((
//   // add your variables here like:
//   font-family-primary: 'Poppins' sans-serif,

//   font-family-secondary:'Poppins' sans-serif,

// ), corporate , corporate);



nb-chat.comments {
  chat-background-color: transparent
}
